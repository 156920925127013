import React from 'react';
import Logo from '../../components/Logo';
import Contact from '../../components/Contact';
import withIntl from '../../i18n/withIntl';
import '../../../assets/styles/Web.scss';

const GdprRules = () => (
  <>
    <section className="Section Section--rules Section--themeLight">
      <div className="Section-main">
        <Logo />
        <h2 className="Section-mainTitle">Privacy Policy</h2>
        <p>
          We have created the „Privacy Policy“ document to ensure the security and confidentiality
          of the information you provide to SQN Ltd. Below you will find the information about the
          personal information we collect, for what purpose and how we store them.
        </p>
        <p>
          SQN Ltd. with its registered office at Kubelikova 1224/42, 130 00 Prague 3, IN 04965850
          (hereinafter referred to as a „Personal Data Controller“) will process provided (or
          publicly obtained) personal data in accordance with the General Regulation on Protection
          of Personal Data (EU) 2016/679.
        </p>
        <h3>1. SQN Ltd. as a Personal Data Controller</h3>
        <p>
          Pursuant to Section 5 (2) of Act No. 101/200 Coll., On the Protection of Personal Data, as
          amended (hereinafter also referred
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          to as \"Personal Data Protection Act"), and in accordance with Article 6 (1) Scripture.
          (a) Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April
          2016 on the protection of individuals with regard to the processing of personal data and
          on the free movement of such data; data.
        </p>
        <h3>2. What information do we collect?</h3>
        <p>
          SQN Ltd. as Personal Data Controller will store and process the information below. This
          information may not be the same for each data Subject. It depends on how much information
          a candidate provides us in CV or in social networks (LinkedIN, Facebook, Instagram,
          Twitter etc.).
        </p>
        <ul>
          <li>company name / company ID / Tax ID</li>
          <li>name and surname, academic title</li>
          <li>photos (if they it s a part of CV or part of profile in social networks)</li>
          <li>date and place of birth</li>
          <li> permanent address, event. contact adress</li>
          <li>contact (phone, mail)</li>
          <li>expected reward / MD rate</li>
          <li>work experience, current and previous employment</li>
          <li>achieved education</li>
          <li>driving license</li>
          <li>knowledge of foreign languages</li>
          <li>link to a profile on social networks</li>
          <li>additional information provided within the CV</li>
          <li>bank account number.</li>
        </ul>
        <h3>3. For what purpose will we process personal data?</h3>
        <p>We collect, store and process your personal information for the following purposes:</p>
        <ul>
          <li>
            to send proposals for participation in IT projects based on Data
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Subjects' requirements or the suitability of the profile
          </li>
          <li>
            to provide a third party, Google LLC based at 1600 Amphitheater Parkway, Mountain View,
            CA 94043, United States, which provides cloud data storage and security services
          </li>
          <li>
            to provide a third party, another processor, with a view to concluding a business case.
            In order for personal data to be forwarded to another processor, the data controller
            must obtain the consent of the data Subject.
          </li>
          <li>
            if someone transmits your personal data to SQN Ltd., they must also inform you in
            advance of this
          </li>
          <li>
            if you provide SQN s.r.o. third party personal data, it is your responsibility to inform
            the person concerned and to ensure that he / she agrees with these privacy terms.
          </li>
          <li>
            we may also use personal data provided by the data Subject to send commercial and other
            communications (eg for the purpose of further education) related to the services
            provided by SQN s.r.o. via email or social networks.
          </li>
        </ul>
        <h3>4. How long will we process your personal information?</h3>
        <p>
          We will process personal data for the period necessary for the purpose for which we
          process the data. If you do not withdraw your consent, we will process your personal data
          for a maximum of 5 years from the moment when the Data Subject has given us consent to the
          processing of personal data.
        </p>
        <h3>5. How will personal data be processed and stored?</h3>
        <p>Personal data will be processed:</p>
        <ul>
          <li>by machine (automated) via computers and computer programs</li>
          <li>in a written way.</li>
        </ul>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <h3>6. Data Subject's rights, information and access to personal data</h3>
        <p>
          As a data Subject, I have the right to access my personal data, I have the right to have
          it rectified or deleted, or to limit my processing. I have the right to request
          information about what personal data is processed and the right to request clarification
          on the processing of personal data.
        </p>
        <p>
          Furthermore, I have the right to object to the processing as well as the right to data
          portability (ie the right to obtain personal data concerning me that I have provided to
          the data controller in a structured, commonly used and machine readable format, see
          Article 20 GDPR).
        </p>
        <p>
          I have the right to withdraw my consent at any time, without prejudice to the lawfulness
          of the processing based on the consent given prior to its withdrawal, if the processing is
          based on Article 6 (1) (a). or Article 9 (2) (a) a) GDPR. This means that I do not have
          such a right, in particular, if the processing is necessary to fulfill the legal
          obligation to which the data controller is Subject.
        </p>
        <p>
          As a data Subject, I am informed that I can revoke my consent to preserve and process the
          data by sending an e-mail to info@sqn.cz. I have the right at any time to choose not to
          receive commercial messages. I can also do so by sending an email to info@sqn.cz.
        </p>
        <p>
          The data controller declares that there is no automated decision making, including
          profiling, as referred to in Article 22 (1) and (4) of the GDPR.
        </p>
        <p>
          The Administrator declares that personal data are not processed for the purposes of
          scientific or historical research or for statistical purposes.
        </p>
        <h3>7. Final Provisions</h3>
        <p>
          As a data Subject, I declare that I am aware of my rights under Sections 12 and 21 of the
          Personal Data Protection Act and its rights under the GDPR.
        </p>
        <p>
          I declare that all information provided is accurate and true and is provided voluntarily.
        </p>
        <p>
          The data controller declares that it will collect personal data to the extent necessary to
          accomplish the purpose and process it only in accordance with the purpose for which it was
          collected.
        </p>
        <p>
          This consent is a free and conscious expression of the data
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Subject's intention, the content of which is the consent of the data Subject to the
          processing of personal data.
        </p>
      </div>
    </section>
    <footer className="Footer">
      <Contact />
    </footer>
  </>
);

export default withIntl(GdprRules);
